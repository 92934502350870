@import 'Styles/Kulturama/includes';

/**
*
* Preamble
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Preamble.Base.module.scss';

.Preamble {
    margin-bottom: 22px;
    font-size: 2rem;
    font-weight: $medium;

    @include media(sm) {
        font-size: 2.2rem;
    }
}
