@import 'Styles/Base/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArchiveList {

    &__ResultLabel {
        @include u-wrap(site);

        position: relative;
        top: 40px;
    }

    &__NoResult {
        @include u-wrap(site);

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

}
