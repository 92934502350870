@import 'Styles/Kulturama/includes';

/**
*
* ApplicationButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ApplicationButton.Base.module';

.ApplicationButton {
    $root: &;

    padding-top: 44px;
    padding-bottom: 44px;

    &--Full {
        margin-bottom: 10px;
    }

    &__Button {
        @include u-button(purple solid large);

        min-width: 270px;
        text-align: center;

        #{$root}--Loading & {
            &:hover {
                background: palette(purple);
            }
        }
    }

    &__Text {
        &::before {
            content: '';
            width: 20px;
            height: 14px;
            position: relative;
            top: 1px;
            display: inline-block;
            margin-right: 11px;
            background: url('#{$basepath}img/bg-icons/base/arrow--white.svg') no-repeat center center;
            background-size: contain;
            transition: opacity .5s;

            #{$root}--Loading & {
                opacity: 0;
            }
        }
    }
}