@import 'Styles/Medborgarskolan/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Billboard.Base.module.scss';

.Billboard {
    $root: &;

    background-color: $coral;
    overflow: hidden;

    &--Purple {
        background-color: $purple;
    }

    &--Image {
        &::before,
        &::after {
            display: none !important;
        }
    }

    &::before,
    &::after {
        content: '';
        display: block;
        position: absolute;
        top: -62px;
        right: -378px;
        width: 621px;
        height: 223px;
        background: $blue;

        // Added to BasePage and preview-body.html in storybook
        clip-path: url('#medborgarskolan-yarn');
    }

    &::before {
        @include media(md) {
            top: 36px;
            right: auto;
            left: calc(((100% - 680px) / 2) + 680px + 50px); // Make sure it is displayed to the right of text
            width: 983px;
            height: 353px;
        }
    }

    &::after {
        top: auto;
        right: auto;
        bottom: -137px;
        left: -332px;
        transform: scaleX(-1);

        @include media(md) {
            bottom: -107px;
            left: -581px;
            width: 917px;
            height: 259px;
            clip-path: url('#medborgarskolan-wave');
            transform: none;
        }
    }

    &__Image {
        display: none;

        #{$root}--Image & {
            display: block;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            background-color: black;
            opacity: .5;
        }
    }

    &__Container {
        max-width: 800px;
        padding: 101px 20px;
        overflow: hidden;
        z-index: 1;

        @include media(md) {
            padding-top: 80px;
            padding-bottom: 80px;
            text-align: center;
        }
    }

    &__Title {
        color: $blue;
        font-size: 3.4rem;
        font-weight: $bold;
        line-height: 1.375;

        #{$root}--Image &,
        #{$root}--Purple & {
            color: white;
        }

        @include media(sm) {
            font-size: 3.5rem;
        }

        @include media(md) {
            font-size: 4.8rem;
        }
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin-top: 18px;
        color: $text-color;
        font-size: 1.6rem;

        #{$root}--Image &,
        #{$root}--Purple & {
            color: white;
        }

        @include media(sm) {
            font-size: 1.8rem;
        }

        @include media(md) {
            margin-left: auto;
            margin-right: auto;
        }
    }

    &__Button {
        @include u-button(primary);

        margin-top: 30px;

        @include media(md) {
            margin-top: 40px;
        }
    }
}
