@import 'Styles/Medborgarskolan/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticle.Base.module.scss';

.CardArticle {
    $root: &;

    &__Container {
        background: white;
        border-radius: 8px;
    }

    &__ImageContainer {
        min-height: 227px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        @include media(sm) {
        }

        &::before {
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            display: block;
        }
    }

    &__Content {
        padding: 30px 20px;
        border-top: none;

        @include media(sm) {
            padding: 24px 24px 32px;
        }

        #{$root}--NoImage & {
            justify-content: center;
            align-items: center;
            flex-grow: 0;
            text-align: center;
        }
    }

    &__Tag {
        flex: 0 0 auto;
        margin-bottom: 10px;
        color: $purple-80;
        font-size: 1.4rem;
        font-weight: $bold;
        letter-spacing: 0.9px;
        text-transform: uppercase;
    }

    &__Title {
        flex: 0 0 auto;
        font-size: 2.6rem;
        margin-bottom: 10px;
        color: $blue;
    }

    &__Text {
        flex: 1 0 auto;
        max-width: 100%;
        font-size: 1.8rem;
    }

    &__ReadMore {
        @include u-button(tertiary);
        width: fit-content;
        flex: 0 0 auto;
        display: inline-block;
        position: relative;
        font-size: 1.8rem;
        margin-top: 20px;
        z-index: 0;

        &::before {
            z-index: z(behind);
        }
    }
}
