@import 'Styles/Tillskararakademin/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArchiveList.Base.module';

.ArchiveList {

    &__List {
        @include media(sm) {
            padding-top: 20px;
        }
    }

    &__ResultLabel {
        font-size: 1.4rem;

        em {
            font-weight: $bold;
        }
    }

    &__NoResult {
        padding: 45px 15px;
        margin: 0;
        font-size: 1.6rem;
        border-top: 1px solid #E7E7E7;

        @include media(md) {
            height: 340px;
            padding: 45px 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultTitle {
        font-size: 2.5rem;
        font-weight: $bold;

        @include media(md) {
            font-size: 3.2rem;
        }
    }

    &__Pagination {
        margin-top: -20px;

        @include media(sm) {
            margin-top: -30px;
        }
    }
}
