@import 'Styles/Base/includes';

/**
*
* CardEntrance
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardEntrance {
    $root: &;

    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: black;
        box-shadow: 10px 10px 20px 0 black;
        opacity: .1;
        z-index: z(behind);
        transition: opacity .2s ease-in;
    }

    &:hover::before {
        opacity: .15;
    }

    &__Link {
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__Container {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        position: relative;
        background: white;
        overflow: hidden;

        #{$root}--Small & {
            padding-top: 6px;
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            height: 6px;
            width: 100%;
            transition: background .3s ease-in-out;

            #{$root}--Small & {
                display: block;
            }
        }
    }

    &__Image {
        position: relative;
        background: white;

        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 120px;

            @include media(sm) {
                height: 132px;
            }
        }

        img {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }

        #{$root}--IsIcon & {
            img {
                width: 32px;
                height: 32px;

                @include media(sm) {
                    width: 36px;
                    height: 36px;
                }
            }
        }

        #{$root}--IsIcon#{$root}--Large & {
            img {
                width: 42px;
                height: 42px;

                @include media(sm) {
                    width: 48px;
                    height: 48px;
                }
            }
        }
    }

    &__Content {
        flex: 1 0 auto;

        #{$root}--Large & {
            padding: 16px;
            text-align: center;

            @include media(sm) {
                padding: 24px;
            }
        }

        #{$root}--Small & {
            padding: 12px;

            @include media(sm) {
                padding: 16px;
            }
        }
    }

    &__Title {
        position: relative;

        #{$root}--Large & {
            font-size: 2rem;

            @include media(sm) {
                font-size: 2.4rem;
            }
        }

        #{$root}--Small & {
            font-size: 1.8rem;

            @include media(sm) {
                font-size: 2rem;
            }

            &::after {
                display: block;
            }
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 2px;
            right: 0;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: transform .3s ease-in-out;

            #{$root}:hover & {
                transform: translateX(8px);
            }
        }
    }

    &__Text {
        margin-top: 12px;
        font-size: 1.6rem;
    }

    &__Button {
        flex: 0 0 auto;
        width: 100%;
        max-width: none !important;

        &::after {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin: -2px 0 -2px 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
            transition: transform .3s ease-in-out;

            #{$root}:hover & {
                transform: translateX(8px);
            }
        }
    }
}
