@import 'Styles/Medborgarskolan/includes';

/**
*
* CardArticleList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardArticleList {
    $root: &;

    padding-top: 40px;
    padding-bottom: 40px;
    background: $blue-20;

    @include media(sm) {
        padding-top: 64px;
        padding-bottom: 64px;
    }

    @include media(lg) {
        padding-top: 88px;
        padding-bottom: 88px;
    }

    &--PageLanding {
        background: $blue-20;
        margin: 0;
        padding-top: 40px;
        padding-bottom: 40px;

        @include media(sm) {
            margin: 0;
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @include media(lg) {
            margin: 0;
            padding-top: 88px;
            padding-bottom: 88px;
        }
    }

    &__Container {
        @include u-wrap(site);
    }

    &__Title {
        margin-bottom: 10px;
        color: $blue;

        @include media(md) {
            margin-bottom: 30px;
            font-size: 4.8rem;
        }
    }

    &__Text {
        max-width: map-get($maxwidths, content);
        margin: auto;
        font-size: 2rem;
        text-align: center;
    }

    &__Grid {
        display: flex;
        flex-wrap: wrap;

        @include media(sm) {
            display: grid;
            grid-gap: 24px;
            grid-template-columns: repeat(2, 1fr);
        }

        @include media(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__Item {
        display: flex;
        width: 100%;
        padding: 0 5px 20px;

        @include media(sm) {
            width: 50%;
        }

        @include media(lg) {
            width: calc(100% / 3);
        }

        @include media(sm) {
            @supports (display: grid) {
                width: auto;
                padding-left: 0;
                padding-right: 0;
            }
        }
    }
}
