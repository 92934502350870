@import 'Styles/Kulturama/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticle.Base.module.scss';

.CardArticle {
    $root: &;

    &:hover {
        &::before {
            opacity: .15;

            @media(hover: none) {
                opacity: 0;
            }
        }
    }

    &::before {
        background: palette(black);
        box-shadow: 0px 2px 9px palette(black);
    }

    &__Container {
        background: white;

        #{$root}:hover & {
            border-color: white;

            @media(hover: none) {
                border-color: palette(black, 11);
            }
        }
    }

    &__ImageContainer {
        min-height: 235px;

        @include media(sm) {
            min-height: 285px;
        }

        &::before {
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            display: block;
        }
    }

    &__Content {
        align-items: center;
        padding: 30px 20px;

        @include media(sm) {
            padding: 40px 30px;
        }

        #{$root}--NoImage & {
            justify-content: center;
            align-items: center;
            flex-grow: 0;
            text-align: center;
        }
    }

    &__Tag {
        flex: 0 0 auto;
        margin-bottom: 10px;
        color: palette(pink);
        font-size: 1.4rem;
        font-weight: $medium;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__Title {
        max-width: 100%;
        flex: 0 0 auto;
        margin-bottom: 10px;
        text-align: center;
        line-height: 1.5;
    }

    &__Text {
        display: none;
    }

    &__ReadMore {
        @include u-button();

        flex: 0 0 auto;
        display: inline-block;
        position: relative;
        margin-top: 20px;
        z-index: 0;

        &::before {
            z-index: z(behind);
        }

        #{$root}:hover & {
            color: white;
        }

        #{$root}:hover &::before {
            opacity: 1;
        }
    }
}
