@import 'Styles/Medborgarskolan/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArchiveList.Base.module';

.ArchiveList {
    $root: &;

    padding-bottom: 16px;
    background: $blue-20;

    @include media(sm) {
        padding-bottom: 40px;
    }

    &__ResultLabel {
        font-size: 1.4rem;
        margin-bottom: 40px;

        em {
            font-weight: $bold;
        }
    }

    &__List {
        @include media(sm) {
            padding-top: 0px;
        }
    }

    &__NoResult {
        @include u-wrap(site);

        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 15px;
        margin-bottom: 30px;
        font-size: 1.4rem;

        @include media(md) {
            font-size: 1.6rem;
        }
    }

    &__NoResultTitle {
        font-size: 3.4rem;
        font-weight: $bold;
        color: $blue;

        @include media(md) {
            font-size: 4.8rem;
            margin-bottom: 32px;
        }
    }

    &__NoResultText {
        font-size: 1.4rem;

        @include media(md) {
            font-size: 1.8rem;
        }

        em {
            font-weight: $bold;
        }
    }

    &__Pagination {
        margin-top: -32px;
    }
}
