@import 'Styles/Base/includes';

/**
*
* EntranceList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EntranceList {
    $root: &;

    position: relative;

    &--Small {
        z-index: z();
    }

    &__Container {
        @include u-wrap(site);
    }

    &--Large {
        padding-top: 40px;
        padding-bottom: 40px;

        @include media(sm) {
            padding-top: 64px;
            padding-bottom: 64px;
        }

        @include media(lg) {
            padding-top: 88px;
            padding-bottom: 88px;
        }
    }

    &--Small {
        &#{$root}--HasNotification {
            margin-top: -16px;
        }

        &#{$root}--NoNotification {
            margin-top: -32px;
        }
    }

    &__Title {
        margin-bottom: 16px;
        font-size: 2.4rem;

        @include media(sm) {
            margin-bottom: 28px;
            font-size: 3.2rem;
        }
    }

    &__List {
        display: flex;
        flex-wrap: wrap;
        margin-left: -16px;
    }

    &__Item {
        display: flex;
        flex-direction: column;
        flex: 0 0 auto;
        width: calc(100% - 16px);
        margin-left: 16px;
        margin-bottom: 16px;

        @include media(sm) {
            width: calc(#{percentage(1/3)} - 16px);
            margin-bottom: 0;
        }
    }
}
