@import 'Styles/Medborgarskolan/includes';

/**
*
* Notification
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Notification.Base.module.scss';

.Notification {
    $root: &;

    text-align: center;
    min-height: 0;
    background: $blue;

    &__Link {
        width: 100%;
        transition: background 0.2s;

        &:hover {
            background: $blue-90;

            @media (hover: none) {
                background: $blue;
            }
        }
    }

    &__Container {
        display: inline-block;
        padding: 20px;
        color: white;
    }

    &__Content {
        svg {
            display: none;
        }

        &::after {
            content: '';
            flex: 0 0 auto;
            width: 24px;
            height: 24px;
            margin-left: 10px;
            background: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg') no-repeat center center;
            background-size: contain;
        }

        > div {
            display: block;
            font-size: 1.8rem;

            strong,
            b {
                font-weight: $bold;
            }
        }
    }
}
