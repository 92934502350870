@import 'Styles/Medborgarskolan/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticleSubject.Base.module.scss';

.CardArticleSubject {
    $root: &;

    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    width: 100%;
    height: 564px;
    max-height: 92vh;
    padding: 30px 20px;
    background: white;
    border-radius: 8px;

    @include media(sm) {
        padding: 40px 24px;
    }

    &__ImageContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 8px;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background: linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.8) 0%,
                    rgba(0, 0, 0, 0) 44%
                ),
                linear-gradient(
                    0deg,
                    rgba(0, 0, 0, 0.2) 0%,
                    rgba(0, 0, 0, 0.2) 100%
                );
        }
    }

    &__Image {
        // Keep transform positioning for smoother animation
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: transform 0.3s ease-in-out;

        #{$root}:hover & {
            transform: translate3d(-50%, -50%, 0) scale(1.1);
        }
    }

    &__Content {
        display: flex;
        flex-direction: column;
    }

    &__Tag {
        font-size: 1.4rem;
        font-weight: $bold;
        text-transform: uppercase;
        color: white;
        z-index: 1;
    }

    &__Title {
        margin-bottom: 10px;
        color: white;
    }

    &__TitleText {
        position: relative;
        padding-right: 23px;
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: z();
    }

    &__Button {
        display: none;
    }
}
