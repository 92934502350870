@import 'Styles/Pahlmans/includes';

/**
*
* ApplicationButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ApplicationButton.Base.module';

.ApplicationButton {
    $root: &;

    &__Button {
        @include u-button(solid large);

        min-width: 192px;
        text-align: center;

        #{$root}--Loading & {
            &:hover {
                background: $primary-color;
            }
        }
    }
}