@import 'Styles/Pahlmans/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Billboard.Base.module.scss';

.Billboard {
    $root: &;

    background-color: $billboard-color;

    &::after {
        content:" ";
        display: block;
        position: absolute;
        top: 0;
        bottom:0;
        right: 0;
        left: 0;
        background-position: right bottom;
        background-color: $billboard-color;
        background-blend-mode: overlay;
        background-repeat: no-repeat;
        opacity: 0.19;
        background-image: $bgImage;
        background-size: auto 80%;
    }

    &--Image {
        &::after {
            opacity: 1;
            background-color: rgba(black, .4);
            background-image: none;
        }
    }


    &__Container {
        max-width: 900px;
        padding: 100px 10px 60px;
        text-align: center;
        z-index: 1;

        @include media(md) {
            padding-top: 110px;
            padding-bottom: 100px;
        }
    }

    &__Title {
        margin-bottom: 30px;
        color: white;
        font-size: 2.8rem;
        font-weight: $normal;
        line-height: 1.375;

        @include media(sm) {

        }

        @include media(md) {
            margin-bottom: 40px;
            font-size: 3.6rem;
        }
    }

    &__Text {
        display: none;
    }

    &__Button {
        @include u-button(ghost);
    }
}
