@import 'Styles/Medborgarskolan/includes';

/**
*
* CardEntrance
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardEntrance.Base.module';

.CardEntrance {
    $root: &;

    &::before {
        display: none;
    }

    &__Container {
        background-color: $purple-20;
        border-radius: 8px;

        #{$root}--Small & {
            padding-top: 0;

            &::before {
                display: none;
            }
        }
    }

    &__Content {
        #{$root}--Large & {
            text-align: left;
        }

        #{$root}--Small & {
            display: inline-flex;
            align-items: center;
            column-gap: 24px;
        }
    }

    &__Title {
        color: $blue;

        #{$root}--Large & {
            font-size: 3.8rem;

            @include media(sm) {
                font-size: 3.8rem;
            }
        }

        #{$root}--Small & {
            font-size: 2.4rem;

            @include media(sm) {
                font-size: 2.6rem;
            }
        }
    }

    &__Image {
        background: $purple-10;
        border-radius: 50%;
        display: inline-block;
        flex: 0 0 auto;
        width: 56px;
        height: 56px;
        overflow: hidden;
        position: relative;

        @include media(sm) {
            width: 64px;
            height: 64px;
        }

        #{$root}--Large & {
            width: 64px;
            height: 64px;

            @include media(sm) {
                width: 72px;
                height: 72px;
            }
        }

        &::before {
            content: '';
            display: block;
            left: 0;
            right: 0;
            height: 32px;
            width: 32px;
            margin: auto;
            position: absolute;
            top: 0;
            background-position: 0%;
            background-repeat: no-repeat;
            background-size: contain;
            bottom: 0;
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow-handdrawn--blue.svg');
            transform: translateX(-200%);
            transition: transform 0.4s cubic-bezier(0.45, 1.45, 0.8, 1);

            #{$root}:hover & {
                transform: translateX(0);
            }

            @include media(sm) {
                width: 36px;
                height: 36px;
            }

            #{$root}--Large & {
                width: 42px;
                height: 42px;

                @include media(sm) {
                    width: 48px;
                    height: 48px;
                }
            }
        }

        img {
            transform: translateX(0);
            transition: transform 0.4s cubic-bezier(0.45, 1.45, 0.8, 1);

            #{$root}:hover & {
                transform: translateX(200%);
            }

            #{$root}--IsImage & {
                border-radius: 50%;
            }
        }
    }

    &__ButtonWrapper {
        margin: 0 24px 24px;
    }

    &__Button {
        @include u-button(primary);

        #{$root}:hover & {
            background: $blue-90;

            @media (hover: none) {
                background: $blue;
            }
        }

        &::after {
            background-image: url('#{$basepath}img/bg-icons/medborgarskolan/new/arrow--white.svg');
            width: 24px;
            height: 24px;
            margin: 0px 0px -6px 8px;
        }
    }
}
