@import 'Styles/Medborgarskolan/includes';

/**
*
* EntranceList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'EntranceList.Base.module';

.EntranceList {
    $root: &;

    padding: 0 !important;

    &--Large {
        margin-top: 48px;
        margin-bottom: 48px;

        @include media(md) {
            margin-top: 72px;
            margin-bottom: 80px;
        }
    }

    &--Small {

        &#{$root}--HasNotification {
            margin-top: 16px;
        }

        &#{$root}--NoNotification {
            margin-top: -47px;
        }
    }

    &__Title {
        color: $blue;
        font-size: 3rem;

        @include media(sm) {
            font-size: 3.8rem;
        }
    }
}
