@import 'Styles/Medborgarskolan/includes';

/**
*
* ApplicationButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ApplicationButton.Base.module';

.ApplicationButton {
    $root: &;

    &:not(#{$root}--Full) {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    &__Button {
        @include u-button(primary);

        min-width: 0;

        #{$root}--Full & {
            @include u-button(block, false);
        }

        #{$root}--Loading & {
            &:hover {
                background: $blue;
            }
        }
    }

    &__Text {
    }

    &__Error {
        padding: 14px 24px;
        border-radius: 8px;
        border: 1px solid $error-color;
        background: white;
    }
}