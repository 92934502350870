@import 'Styles/Base/includes';

/**
*
* ApplicationButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ApplicationButton {
    $root: &;

    @include u-wrap(site);
    
    display: flex;
    justify-content: center;
    padding-top: 37px;
    padding-bottom: 37px;

    &--Full {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &__Error {
        @include u-wrap(content);

        text-align: center;
        color: $error-color;

        a {
            color: $link-color;
        }
    }

    &__Button {
        min-width: 300px;

        #{$root}--Full & {
            @include u-button(block, false);
        }

        #{$root}--Loading & {
            cursor: auto;
            opacity: 0.8;

            &::before {
                opacity: 0;
            }
        }
    }

    &__Text {
        position: relative;
        transition: opacity .5s;

        #{$root}--Loading & {
            opacity: 0;
        }
    }

    &__Loader {
        position: absolute !important;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        width: 30px;
        height: 30px;
        margin: auto;
        border-radius: 50%;
        opacity: 0;
        transition: opacity .5s;

        &::before {
            position: relative;
            border: 2px solid rgba(white, 0.2);
            border-left-color: white;
            transform: translateZ(0);

            :global {
                animation: spin 1.1s infinite linear;
            }
        }

        &::before,
        &::after {
            content: '';
            display: block;
            width: calc(100% - 4px);
            height: calc(100% - 4px);
            border-radius: 50%;
        }

        #{$root}--Loading & {
            opacity: 1;
        }
    }
}
