@import 'Styles/Dansskolanentre/includes';

/**
*
* Notification
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Notification.Base.module.scss';

.Notification {
    $root: &;

    background: $primary-color;

    // Background behind hero with curve
    &::before {
        content: '';
        display: block;
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 100px;
        background: $primary-color;
        z-index: -1;
    }

    &__Container {
        @include u-wrap(site);

        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
    }

    &__Content {
        p {
            color: white !important;
        }
    }
}
