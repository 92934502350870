@import 'Styles/Dansskolanentre/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticleSubject.Base.module.scss';

.CardArticleSubject {
    $root: &;

    position: relative;
    display: flex;
    flex: 1 0 auto;
    color: white;
    border-radius: 8px;
    background: $gradient-color;
    z-index: 0;

    @include media(sm) {
        border-radius: 12px;
    }

    @include media(md) {
        max-width: 288px;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $primary-color;
        border-radius: 8px;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        @include media(sm) {
            border-radius: 12px;
        }
    }

    &:hover::before {
        opacity: 1;
    }

    &__Content {
        &--Medborgarskolan {
            display: none;
        }
    }

    &__Button {
        flex: 1 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 72px;
        width: 100%;
        position: relative;
        padding: 16px (52px + 16px) 16px 16px;
        color: white;
        font-size: 1.8rem;
        font-weight: $bold;
        line-height: 1.4;
        word-break: break-word;

        @include media(sm) {
            padding: 24px (52px + 24px) 24px 24px;
            min-height: 104px;
            font-size: 2rem;
        }

        svg {
            display: none;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 16px;
            width: 44px;
            height: 44px;
            margin: auto;
            background-image: url('#{$basepath}img/bg-icons/dansskolanentre/arrow--white.svg');
            background-position: center;
            background-color: rgba(white, 0.2);
            background-repeat: no-repeat;
            background-size: 24px;
            border-radius: 100%;

            @include media(sm) {
                right: 24px;
            }
        }
    }
}
