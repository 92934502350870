@import 'Styles/Kulturama/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Billboard.Base.module.scss';

.Billboard {
    background-color: palette(black);
    background-position: center;
    background-size: cover;

    &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: palette(black);
        opacity: 0.45;
    }

    &__Container {
        max-width: 1000px;
        padding: 100px 20px 100px;
        text-align: center;
        z-index: 1;

        @include media(sm) {
            padding: 100px 40px 100px;
        }
    }

    &__Title {
        color: palette(white);
        font-size: 2.3rem;
        font-weight: $bold;
        line-height: 1.4;

        @include media(sm) {
            font-size: 3.5rem;
        }
    }

    &__Text {
        display: none;
    }

    &__Button {
        margin-top: 35px;
    }

}
