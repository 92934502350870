@import 'Styles/Pahlmans/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticle.Base.module.scss';

.CardArticle {
    $root: &;

    &:hover {
        &::before {
            opacity: .3;

            @media(hover: none) {
                opacity: 0;
            }
        }
    }

    &::before {
        background: black;
        box-shadow: 0px 6px 9px black;
    }

    &__Container {
        position: relative;
        background: white;
        padding: 20px 20px 40px;

        &:after{
            content: '';
            position: absolute;
            width: 23px;
            height: 15px;
            bottom: 40px;
            left: 32px;
            background: $bgArrow no-repeat;

            @include media(sm) {
                left: 30px;
            }
        }
        #{$root}:hover & {
            border-color: white;

            @media(hover: none) {
                border-color: palette(black, 11);
            }
        }
    }

    &__ImageContainer {
        height: 192px;

        &::before {
            content: '';
            width: 100%;
            height: 0;
            padding-bottom: 75%;
            display: block;
        }
    }

    &__Content {
        align-items: left;
        padding: 36px 12px 40px 12px;

        #{$root}--NoImage & {
            justify-content: center;
            align-items: left;
            flex-grow: 0;
            text-align: left;
        }
    }

    &__Tag {
        flex: 0 0 auto;
        margin-bottom: 10px;
        color: $tag-color;
        font-size: 1.2rem;
        font-weight: $bold;
        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__Title {
        max-width: 100%;
        flex: 0 0 auto;
        margin-bottom: 10px;
        text-align: left;
        font-size: 2.4rem;
        line-height: 1.2;
    }

    &__Text {
        display: none;
    }

    &__ReadMore {
        //@include u-button();

        flex: 0 0 auto;
        display: none;
        position: relative;
        margin-top: 20px;
    }
}
