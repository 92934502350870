@import 'Styles/Base/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardArticleSubject {
    $root: &;

    position: relative;

    &__ImageContainer {
        position: relative;
        overflow: hidden;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
