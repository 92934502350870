@import 'Styles/Tillskararakademin/includes';

/**
*
* Notification
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Notification.Base.module.scss';

.Notification {
    $root: &;

    background: palette(green);

    &__Container {
        @include u-wrap(site);

        padding-top: 20px;
        padding-bottom: 20px;
        color: palette(white);
    }

    &__Content {
        p {
            color: white !important;
        }

        svg {
            stroke: white;
        }
    }
}
