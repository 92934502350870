@import 'Styles/Dansskolanentre/includes';

/**
*
* ApplicationButton
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ApplicationButton.Base.module';

.ApplicationButton {
    $root: &;

    &--Error {
        padding-top: 10px;
        padding-left: 28px;
        padding-right: 28px;
    }

    &__Button {
        @include u-button(solid large);

        min-width: 192px;
        text-align: center;

        #{$root}--Loading & {
            &:hover {
                background: $primary-color;
            }
        }
    }

    &__Error {
        padding: 14px 24px;
        border-radius: 8px;
        border: 1px solid $error-color;
        background: white;
        z-index: 1;

        @include media(sm) {
            border-radius: 12px;
        }
    }
}