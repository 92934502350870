@import 'Styles/Pahlmans/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArchiveList.Base.module';

.ArchiveList {

    &__ResultLabel {
        font-size: 1.4rem;

        em {
            font-weight: $bold;
        }
    }

    &__NoResult {
        padding: 30px 15px 60px;
        font-size: 1.6rem;

        @include media(md) {
            height: 268px;
            padding: 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultTitle {
        margin-bottom: 5px;
        font-size: 2rem;
        font-weight: $medium;

        @include media(md) {
            font-size: 2.4rem;
        }
    }

    &__NoResultText {
        padding: 0;
        margin: 0 auto;
        max-width: 524px;
        color: palette(black, 86);
        font-size: 2.4rem;
        text-align: center;
        font-weight: $medium;
        font-family: $font-heading;

        em {
            font-weight: $black;
        }

        @include media(sm) {
            font-size: 3.2rem;
        }
    }

    &__Pagination {
        margin-top: -20px;
        padding-bottom: 40px;

        @include media(md) {
            margin-top: -80px;
        }
    }
}
