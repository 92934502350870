@import 'Styles/Dansskolanentre/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticle.Base.module.scss';

.CardArticle {
    $root: &;

    position: relative;

    &__Container {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        min-height: 300px;
        border-radius: 8px;
        overflow: hidden;
        z-index: 0; // Fix for safari where border radius isn't added to image

        @include media(sm) {
            border-radius: 12px;
        }

        @include media(md) {
            min-height: 381px;
        }
    }

    &__ImageContainer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &::before {
            content: '';
            width: 100%;
            height: 0;
            display: block;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-image: linear-gradient(0deg, rgba(black, .65) 0%, rgba(black, 0) 58.01%);
        }
    }

    &__Image {
        margin: 0;
        transform: translateZ(0) scaleX(1);
        transition: transform .3s ease-in-out;

        #{$root}:hover & {
            transform: translateZ(0) scale3d(1.05, 1.05, 1);

            @media(hover: none) {
                transform: translateZ(0) scaleX(1);
            }
        }
    }

    &__Content {
        flex: 0 0 auto;
        justify-content: flex-end;
        align-items: left;
        position: relative;
        flex-grow: 0;
        text-align: left;
        padding: 24px 16px;

        @include media(sm) {
            padding: 32px 24px;
        }

        @include media(md) {
            padding: 40px 32px;
        }
    }

    &__Tag {
        color: white;
        font-size: 1.4rem;
        font-weight: $medium;
        line-height: 1.17;
        letter-spacing: 0.7px;
        margin-top: 40px;
        text-transform: uppercase;
    }

    &__Title {
        max-width: 328px;
        color: white;
        margin-top: 12px;
        font-weight: $bold;
        font-size: 2.4rem;
        line-height: 1.37;

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__Text {
        display: none;
    }

    &__ReadMore {
        flex: 0 0 auto;
        display: none;
        position: relative;
        margin-top: 20px;
    }
}
