@import 'Styles/Dansskolanentre/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArchiveList.Base.module';

.ArchiveList {

    &__List {
    }

    &__ResultLabel {
        font-size: 1.4rem;
        top: 20px;

        @include media(md) {
            top: 40px;
        }

        em {
            font-weight: $bold;
        }
    }

    &__NoResult {
        padding: 45px 15px;
        margin: 0;
        font-size: 1.6rem;

        @include media(md) {
            height: 340px;
            padding: 45px 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultTitle {
        margin-bottom: 16px;
        font-size: 2.4rem;
        font-weight: $bold;

        @include media(sm) {
            font-size: 2.6rem;
        }
    }

    &__NoResultText {
        font-size: 1.6rem;
        font-weight: $light;

        @include media(sm) {
            font-size: 1.8rem;
        }
    }

    &__Pagination {
        margin-top: -20px;

        @include media(sm) {
            margin-top: -30px;
            margin-bottom: 60px;
        }
    }
}
