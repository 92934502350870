@import 'Styles/Medborgarskolan/includes';

/**
*
* EventContact
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.EventContact {
    &__Item {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &__Title {
        margin-bottom: 10px;
    }

    p, a {
        margin-bottom: 5px;
    }

    a {
        display: block;
        cursor: pointer;
        color: $blue;

        &:hover {
            text-decoration: underline;

            @media(hover: none) {
                text-decoration: none;
            }
        }
    }
}
