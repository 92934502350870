@import 'Styles/Base/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Billboard {
    $root: &;

    position: relative;

    &__Wrap {
        width: 100%;
        height: 100%;
    }

    &__Title {
        color: inherit;
    }

    &__Text {
        color: inherit;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Container {
        position: relative;
        margin-left: auto;
        margin-right: auto;
    }

}
