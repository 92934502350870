@import 'Styles/Pahlmans/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticleSubject.Base.module.scss';

.CardArticleSubject {
    $root: &;

    flex: 1 0 auto;
    width: 100%;
    padding: 27px 20px;
    background: white;
    border-left: 3px solid $primary-color;

    @include media(sm) {
        padding: 27px 30px;
    }

    &__Content {
        p {
            display: none;
        }
    }

    &__Title {
        font-size: 2.4rem;

        #{$root}:hover & {
            color: $primary-color;

            @media(hover: none) {
                color: inherit;
            }
        }
    }

    &__TitleText {
        display: block;
        position: relative;
        padding-right: 23px;

        &:after{
          content: '';
          background-image: $bgArrow;
          position: absolute;
          top: 6px;
          right: 0;
          width: 23px;
          height: 15px;
          background-repeat: no-repeat;
          background-position: right center;
        }
        svg {
            display: none;
            position: absolute;
            bottom: 8px;
            right: 0;

            @include media(sm) {
                bottom: 10px;
            }

            * {
                fill: $primary-color;
            }
        }
    }

    &__Link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: z();
    }

    &__Button {
        display: none;
    }
}
