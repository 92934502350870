@import 'Styles/Pahlmans/includes';

/**
*
* Notification
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Notification.Base.module.scss';

.Notification {
    $root: &;

    font-family: $font-heading;
    background: $secondary-color;

    &__Container {
        @include u-wrap(site);

        padding-top: 20px;
        padding-bottom: 20px;
        color: white;
    }

    &__Content {
        display: flex;
        align-items: center;

        svg {
            display: none;
        }

        &::before {
            width: 29px;
            height: 19px;
            background-repeat: no-repeat;
            margin-right: 18px;
            content:'';
            background-image: url('#{$basepath}img/bg-icons/pahlmans/arrow--white.svg');
        }

        > div {
            p {
                font-weight: $medium;
                color:white;

                @include media(sm) {
                    font-size: 2.2rem;
                }
            }
        }
    }
}
