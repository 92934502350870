@import 'Styles/Base/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardArticle {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0;
        z-index: z(behind);
        transition: opacity .2s ease-in;
    }

    &__Link {
        position: absolute;
        top:0;
        right: 0;
        bottom: 0;
        left: 0;
        cursor: pointer;
        z-index: z();
    }

    &__Container {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;

        #{$root}--NoImage & {
            justify-content: center;
        }
    }

    &__ImageContainer {
        position: relative;
        margin: -1px;
        flex: 0 1 auto;
        overflow: hidden;
    }

    &__Image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__Content {
        display: flex;
        flex-flow: column nowrap;
        flex: 1 1 auto;
        padding: 30px 20px;
        border-top: none;
    }
}
