@import 'Styles/Kulturama/includes';

/**
*
* ArchiveList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'ArchiveList.Base.module';

.ArchiveList {
    background: palette(black, 3);

    &__ResultLabel {
        font-size: 1.4rem;

        em {
            font-weight: $bold;
        }
    }

    &__NoResult {
        padding: 45px 15px;
        margin: 0;
        font-size: 1.6rem;

        @include media(md) {
            height: 340px;
            padding: 45px 30px;
            font-size: 1.8rem;
        }
    }

    &__NoResultTitle {
        margin-bottom: 5px;
        font-size: 1.8rem;
        font-weight: $medium;

        @include media(md) {
            font-size: 2rem;
        }
    }

    &__Pagination {
        margin-top: -20px;
        padding-bottom: 40px;

        @include media(sm) {
            margin-top: -50px;
        }

        @include media(md) {
            margin-top: -60px;
        }

        @include media(lg) {
            margin-top: -80px;
        }
    }
}
