@import 'Styles/Tillskararakademin/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Billboard.Base.module.scss';

.Billboard {
    background-position: center;
    background-size: cover;
    padding: 45px 20px;
    overflow: hidden;

    @include media(sm) {
        padding: 85px 20px;

        :global(.PageHomeSchool) & {
            padding: 45px 20px;
        }
    }

    &__Container {
        max-width: map-get($maxwidths, reg);
        border: 2px solid black;
        display: flex;
        flex-flow: column;
        padding: 20px;

        @include media(sm) {
            padding: 60px 90px;
        }

        &:after {
            content: '';
            width: 280px;
            height: 280px;
            background: url(#{$basepath}img/bg-icons/tillskararakademin/hands.webp) no-repeat center center;
            position: absolute;
            background-size: contain;
            z-index: map-get($z-index, behind);
            bottom: -60px;
            right: -80px;
            @include media(lg) {
                width: 450px;
                height: 450px;
                bottom: -100px;
                right: -150px;
            }
        }

        :global(.PageHomeSchool) & {
            &:after {
                background-image: url(#{$basepath}img/bg-icons/tillskararakademin/scissors.webp);
                bottom: -90px;
                right: -100px;
                @include media(lg) {
                    width: 400px;
                    height: 400px;
                    bottom: -120px;
                }
            }
        }
    }

    &__Title {
        color: palette(black);
        font-size: 2.4rem;
        font-weight: $normal;
        line-height: 1.4;
        max-width: 85%;

        @include media(sm) {
            font-size: 3.2rem;
        }
    }

    &__Text {
        display: none;
    }

    &__Button {
        margin-top: 20px;
        margin-bottom: 0;
        background-color: none;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;

        @include media(sm) {
            margin-top: 30px;
        }
    }

}
