@import 'Styles/Base/includes';

/**
*
* Notification
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.Notification {
    $root: &;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 80px;

    &__Link {
        display: block;
    }

    &__Container {

        @include media(sm) {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    &__Content {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            display: block;
            flex: 0 0 auto;
            width: 15px;
            height: 14px;
            margin-right: 15px;
            fill: white;
            stroke: white;
        }

        > div {
            p {
                margin-top: 0;
            }
        }
    }
}
