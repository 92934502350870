@import 'Styles/Dansskolanentre/includes';

/**
*
* Preamble
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Preamble.Base.module.scss';

.Preamble {
    margin-top: 16px;
    margin-bottom: 24px;
    color: $text-color;
    font-size: 1.8rem;
    font-weight: $medium;

    @include media(sm) {
        font-size: 2rem;
        margin-bottom: 32px;
    }
}
