@import 'Styles/Dansskolanentre/includes';

/**
*
* Billboard
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'Billboard.Base.module.scss';

.Billboard {
    $root: &;

    @include u-wrap(site);

    position: relative;
    color: $text-color;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 40px;

    @include media(sm) {
        margin-top: 64px;
        margin-bottom: 64px;
    }

    @include media(lg) {
        margin-top: 96px;
        margin-bottom: 96px;
    }

    &--Image {
        color: white;
    }

    &__Wrap {
        position: relative;
    }

    &__Image {
        border-radius: 8px;
        overflow: hidden;

        @include media(sm) {
            border-radius: 12px;
        }
    }

    &__Container {
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;

        #{$root}--Image & {
            min-height: 260px;
            padding: 60px 24px;

            @include media(xs) {
                padding: 60px 40px;
            }

            @include media(sm) {
                min-height: 370px;
                padding: 72px 60px;
            }

            @include media(md) {
                padding: 90px;
            }
        }
    }

    &__Title {
        font-size: 3rem;
        font-weight: $bold;
        line-height: 1.2;

        @include media(sm) {
            max-width: 85%;
            font-size: 4.6rem;
        }
    }

    &__Text {
        display: none;
    }

    &__Button {
        margin: 20px auto 0;
        background-color: none;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: flex-start;

        @include media(sm) {
            margin-top: 30px;
        }
    }

}
