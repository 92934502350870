@import 'Styles/Kulturama/includes';

/**
*
* CardArticleSubject
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

@import 'CardArticleSubject.Base.module.scss';

.CardArticleSubject {
    $root: &;
    display: flex;
    flex: 1;
    border-radius: 4px;
    background: palette(white);
    z-index: 0;

    &__Content {
        &--Medborgarskolan {
            display: none;
        }
    }

    &__ImageContainer {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-radius: 4px;
        overflow: hidden;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            background-image: linear-gradient(to bottom, rgba(black, .2), rgba(black, .9));
        }
    }

    &__Image {
        // Keep transform positioning for smoother animation
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        transition: transform .3s ease-in-out;

        #{$root}:hover & {
            transform: translate3d(-50%, -50%, 0) scale(1.1);
        }
    }

    &__Button {
        position: relative;
        flex: 1;
        min-height: 100px;
        padding: 32px 20px;
        font-size: 1.8rem;
        font-weight: bold;
        text-align: center;
        border-radius: 4px;

        #{$root}--Image & {
            color: white;
        }

        svg {
            fill: palette(pink);
            margin-right: 10px;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            z-index: z(behind);
            opacity: 0;
            box-shadow: -3px 6px 16px rgba(black, .37);
            transition: opacity .2s ease-in-out;

            #{$root}:hover & {
                opacity: 1;
            }
        }
    }
}
